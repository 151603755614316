import { createRouter, createWebHistory } from 'vue-router';
import Home from '../components/Home.vue';
import Cases from '../components/Cases.vue';
import News from '../components/News.vue';
import About from '../components/About.vue';
import Contact from '../components/Contact.vue';

const routes = [
  { path: '/', component: Home },
  { path: '/cases', component: Cases },
  { path: '/news', component: News },
  { path: '/about', component: About },
  { path: '/contact', component: Contact }
  // Add more routes as needed
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;

