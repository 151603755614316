<template>
<div id="cases-id" class="container-fluid">
<div class="accordion" id="accordionExample">
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
      <h3>Habtamu Ayalew's attack on Fano support group in Boston</h3>  
    </button>
    </h2>
    <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      <p  style="text-align: left;">We initially became aware of Habtamu Ayaley's attack on our group with false narratives in January of 2024. We made the decision not to publicly respond in order to prevent escalating the situation and diverting attention from our overarching objectives. We strived to focus our attention on broader objectives rather than becoming entangled in a potentially counterproductive conflict. We held the belief that maintaining unity and remaining committed to long-term goals were of paramount importance. However, his recent escalation in spreading unfounded accusations against certain FANO groups on the ground has reached concerning levels, fostering a climate of distrust and division among the public.</p>

<div class="container-fluid">
        <div class="row">
        <div class="col">
        <ol>

<li><b>False Narratives:</b> Habtamu Ayalew targeted our group during the Ethio 360 live program on 01/02/2024 relying on misinformation supplied by his informant in Boston, Amsalu Asnake. Subsequently, he deleted the video post from that day. Nevertheless, we have obtained the original video. We are deeply concerned about the negative impact his actions may have on the reputation of Ethio 360 media. The event he referred to in his rant was actually scheduled to take place in Springfield, Maryland, not in Boston. He intentionally distorted the information to suit his narrative. We sold some tickets in Boston just to support the event in Maryland. The event was ultimately canceled because Habtamu attempted to intimidate the guests.</li>

<li>Habtamu, whose only source of income was YouTube and now who relies on our monthly contributions, vents his frusration on the Fano support group in Boston</li>
<li><div v-html="tiktokEmbedCode"></div></li>

<li><b>The Black sheep:</b> The aftermath of the 2005 Ethiopian election was marked by widespread protests, violence, and political unrest. Hundreds of people were killed, and thousands were arrested. This period had significant implications for Ethiopia's political landscape, including the imposition of a state of emergency and a crackdown on dissent. At that time, certain members of our group, who were then affiliated with Kinijit, approached the Boston Student Association to suggest collaborating on organizing a candlelight vigil. Amsalu was the primary individual who opposed joining. He never participated in any protests during the TPLF era, whether they were held in Boston or during the group trips we took to Washington DC or New York. The first time we saw Amsalu protesting in Boston was under the guise of "Balderas" when dictator Abiy Ahmed attacked TPLF. He also never participated in public forums that denounce TPLF. We always had reservations about him. He once tried to exploit the internal division within Kinijit by hosting Hailu Shawl in Boston but without much success. Shortly afterward, we witnessed Hailu Shawl bowing to the dictator Meles Zenawi on live TV.
</li>
<li><b>Support for Balderas:</b> Our group members always supported Balderas individually. We collectively contributed $2,000 US at a fundraising event held at Danehy Park in Cambridge, MA, when Eskindir Nega visited Boston. When APF was formed under Eskindir, we didn’t hesitate to join.</li>

<li><b>Support for APF:</b> In the summer of 2023, we became members of the APF support group in Boston after being approached by Amsalu Asnake. Our involvement commenced with the fundraising event held at Starbucks in Cambridge on June 5th, 2023, where we successfully raised $17,000 US. Initially, we held the belief that AFP provided support to all FANOs, only to find out later to our dismay that this assumption was entirely false. We were informed that FANOs needed to come under APF to receive support. However, after speaking with Shaleqa Dawit, we learned that there was some flexibility to support FANOs outside of APF.  Despite waiting for weeks and months, it did not materialize. Amsalu continued to deceive us to the extent that we reached a breaking point and could no longer tolerate it. We were consistently caught off guard by his recurrent lies during numerous meetings held in Cambridge. We refrained from confronting him for fear that it would disrupt the meeting.</li>

<li><b>Bad Actors:</b> Habtamu and Amsalu's method of supporting FANOs involved coercing them to join APF in exchange for $5,000 US. This sparked a considerable uproar within the FANO community, leading to divisions among FANOs. Some prominent FANO groups discredited APF and began appointing their own representatives in the diaspora. We understand that the honeymoon between these two individuals will soon end, as the only thing that binds them is their affinity for deceit and manipulation. Whenever challenged, invoking Eskindir’s name serves as their defense mechanism, believed to ward off demons that confront their adversaries. We hold Eskindir in high regard, yet we also have reservations regarding his recent choices for political appointees in North America.</li>


<li><b>Ethio 360 media:</b> We have been providing financial support to this media outlet since its establishment and cannot overlook the role it has played in exposing the immature dictator Abiy Ahmed who has emerged by accident in Ethiopia. However, we are disheartened to witness Habtamu dragging this esteemed media organization into the gutter. The danger of what people discuss behind closed doors for media consumption lies in the potential for misinformation, manipulation, and harm. The other danger is that Habtamu Ayalew's influence on 360 mirrors Abiy's impact on Ethiopia and Berhanu Nega's influence on Ezema.</li>

</ol>
<h5>It is high time that we cease engaging in hate mongering amongst ourselves and instead work towards the betterment of Ethiopia.</h5>
<h3>Ethiopia shall prevail!</h3>
<em>FANO support group in Boston</em>
<p></p>
<em>Disclaimer: The views and opinions expressed in this media content are those of the author and do not necessarily reflect the official policy or position of  Ethiowatchdog.</em>
</div>
</div>
</div>

    </div>
    </div>
  </div>


  <!--div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
      Case 2
      </button>
    </h2>
    <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
      Case 2
      </button>
    </h2>
    <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      </div>
    </div>
  </div-->

</div>
  <p></p>
  <em>Disclaimer: The views and opinions expressed in this media content are those of the author and do not necessarily reflect the official policy or position of  Ethiowatchdog.</em>
</div>
</template>
<script>
export default {
  name: 'cases-id',
  data() {
    return {
      tiktokEmbedCode: `
        <!-- Paste your TikTok embed code here -->
        <iframe src="https://www.tiktok.com/embed/v2/7341533075538758958?lang=en-US&no-controls=1" 
                 width="100%"
                 height="800"
	        frameborder="0" 
                allowfullscreen></iframe>
      `
    };
  }
}
</script>
