<template>
  <div id='contact-id' class="container">
<div class="row justify-content-center">
<h5>Email: ethiowatchdog@gmail.com</h5>
<div class="card text-dark bg-light mb-3" style="max-width: 18rem;">
  <div class="card-header">Contact us</div>
  <div class="card-body">
    <h5 class="card-title">Please fill out the form below so that we can update you on Ethiopian media outlets.</h5>
  </div>

    <form @submit.prevent="submitForm">
      <div class="form-group">
        <label for="name">Name:</label>
        <input type="text" class="form-control" id="name" v-model="name" required>
      </div>
      <div class="form-group">
        <label for="email">Email:</label>
        <input type="email" class="form-control" id="email" @blur="validateEmail" v-model="email" required>
        <p v-if="!isValidEmail && email" :style="{ color: inputColor }" >Please enter a valid email address.</p>
      </div>
      <div class="form-group">
        <label for="phone">Phone:</label>
        <input type="phone" class="form-control" id="phone" @blur="validatePhoneNumber" v-model="phone" required>
        <p v-if="!isValidPhoneNumber && phone" :style="{ color: inputColor }" >Please enter a valid phone number.</p>
      </div>
      <div class="form-group">
        <label for="message">Message:</label>
        <textarea class="form-control" id="message" rows="5" v-model="message" required></textarea>
      </div>
      <button type="submit" class="btn btn-primary">Submit</button>
      <br>
    </form>

    <!-- Message to display on submission -->
    <div v-if="submitted" class="alert alert-success mt-3" role="alert">
        Thank you for your submission! 
    </div>

</div>
</div>
  </div>
</template>

<script>
//import { ref, onMounted } from 'vue';
//import firebaseExport from '@/firebase';
import { db } from '@/firebase';
import { ref, push } from 'firebase/database';

export default {
  name: 'contact-id',
  data() {
    return {
      name: '',
      email: '',
      phone: '',
      message: '',
      inputColor: 'red',
      isValidEmail: true,
      isValidPhoneNumber: true,
      submitted: false
    };
  },
  methods: {
    submitForm() {
      if(!this.isValidEmail || !this.isValidPhoneNumber) {
          this.submitted = false
          return
      }

      const newData = {
        name: this.name,
        email: this.email,
        phone: this.phone,
        message: this.message
      };
      const itemsRef = ref(db, 'users/');
        
        // Push new item to Firebase (generates a unique key)
        push(itemsRef, newData)
          .then(() => {
            this.submitted = true;
            console.log('Item added successfully');
            this.newData = ''; // Clear the input field
          })
          .catch(error => {
            console.error('Error adding item:', error);
          });
      },
          validatePhoneNumber() {
          // Regular expression for phone number validation
          const phoneRegex = /^\d{10}$/; // Matches a 10-digit number
          this.isValidPhoneNumber = phoneRegex.test(this.phone);
      },
      validateEmail() {
          // Regular expression for email validation
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          this.isValidEmail = emailRegex.test(this.email);
      }
     

      // Handle form submission here
      //console.log('Form submitted:', this.name, this.email, this.message);
     // You can add logic to send form data to a backend or perform other actions
    }
}
</script>

<style scoped>
/* Add scoped styles for this component if necessary */
</style>

