// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import { getFunctions, httpsCallable } from "firebase/functions";
import 'firebase/functions'


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

//databaseURL: "https://ethiowatchdog-default-rtdb.firebaseio.com",
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDlbjGUHiQqdjNk2J69Nsw_E_vsbTwg5a0",
  authDomain: "ethiowatchdog.firebaseapp.com",
  projectId: "ethiowatchdog",
  databaseURL: "https://ethiowatchdog.firebaseio.com",
  storageBucket: "ethiowatchdog.appspot.com",
  messagingSenderId: "412777881017",
  appId: "1:412777881017:web:0ac94bfe18a9cc48f1aa68",
  measurementId: "G-XZYTZDGFHW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
const db = getDatabase(app);
const functions = getFunctions(app);
export { app, db, functions, httpsCallable };

/*
const firebaseExport = {
    app,
    db,
    functions,
    httpsCallable
};

export default firebaseExport;
*/

