<template>
<div id="about-id" class="container-fluid">
<div class="accordion" id="accordionExample">


<div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
      <h3>VOA Amharic News - Audio</h3>
    </button>
    </h2>
    <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
      <div class="accordion-body">

       <div class="container-fluid">
           <div class="row">
               <div class="col">
                   <ol>
                   <li v-for="item in parsedAudioData" :key="item.guid">
                   <a :href="item.link" target="_blank">{{ item.title }} - {{ item.pubDate }}</a>
                   </li>
                   </ol>
               </div>
           </div>
      </div>  

     </div>
   </div>
</div>

<div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
      <h3>VOA Amharic News - Video</h3>
      </button>
    </h2>
    <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div class="accordion-body">
          <div class="container-fluid">
           <div class="row">
               <div class="col">
                   <ol>
                   <li v-for="item in parsedVideoData" :key="item.guid">
                   <a :href="item.link" target="_blank">{{ item.title }} - {{ item.pubDate }}</a>
                   </li>
                   </ol>
               </div>
           </div>
      </div>         

     </div>
    </div>
  </div>

</div>
</div>
</template>

<script>
import axios from 'axios';
import Parser from 'rss-parser';

export default {
  name: 'about-id',
  data() {
    return {
      parsedAudioData: [],
      parsedVideoData: [],
      CORS_PROXY: "https://us-central1-ethiowatchdog.cloudfunctions.net/voa?url="
    };
  },
  async mounted() {
    // Fetch RSS feed data from backend server
    this.getVOAAudioNews();
    this.getVOAVideoNews();
  },
  methods: { 
    // https://amharic.voanews.com/p/5779.html 
    async getVOAAudioNews() {
        const feedUrl = "https://amharic.voanews.com/api/zqvjtekq-q"; // Replace with your RSS feed URL
        try {
           const response = await axios.get(this.CORS_PROXY + feedUrl); 
           const parser = new Parser(); // or new FeedParser() for Node.js environment
           const feed = await parser.parseString(response.data);
           this.parsedAudioData = feed.items;
        } catch (error) {
          console.error('Error fetching or parsing RSS feed:', error);
        } 
     },
     async getVOAVideoNews() {
        const feedUrl = "https://amharic.voanews.com/api/zmpoqpe$joq_"; // Replace with your RSS feed URL
        //const feedUrl = "http://amharic.voanews.com/api/zy--yeqv$y"; // Replace with your RSS feed URL
        try {
            const response = await axios.get(this.CORS_PROXY + feedUrl); 
            const parser = new Parser(); // or new FeedParser() for Node.js environment
            const feed = await parser.parseString(response.data);
            this.parsedVideoData = feed.items;
        } catch (error) {
          console.error('Error fetching or parsing RSS feed:', error);
        } 
     }
   }
};
</script>

